<template>
  <div class="price-list-details-page">
    <PriceListDetails />
  </div>
</template>

<script>
import PriceListDetails from "@/components/PriceListDetails.vue";

export default {
  name: "PriceListDetailsPage",
  components: {
    PriceListDetails,
  },
};
</script>

<style>
.price-list-details-page {
  background: #E1F2F9;
  min-height: calc(100vh - 100px);
}
</style>
