
































































import { Component, Vue, Watch } from "vue-property-decorator";
import { EnumPriceLIstPaths } from "../enums/EnumPriceLIstPaths";
import ProductPrice from "./ProductPrice.vue";
import { WalletIlustration, NotifyIlustration } from "./ilustrations";
import { TranslateResult } from "vue-i18n";

@Component({
  components: {
    ProductPrice,
    WalletIlustration,
    NotifyIlustration
  }
})
export default class PriceListDetails extends Vue {
  policeProducts = [
    {
      price: 620,
      time: 15,
      title: this.$t(
        "piceListContent.processingAndJustice.details.firstItem.header"
      ) as TranslateResult,
      description: this.$t(
        "piceListContent.processingAndJustice.details.firstItem.info"
      ) as TranslateResult,
      isIndividual: false
    },
    {
      price: 250,
      time: 15,
      title: this.$t(
        "piceListContent.processingAndJustice.details.secondItem.header"
      ) as TranslateResult,
      description: this.$t(
        "piceListContent.processingAndJustice.details.secondItem.info"
      ) as TranslateResult,
      isIndividual: false
    },
    {
      price: 800,
      time: 20,
      title: this.$t(
        "piceListContent.processingAndJustice.details.thirdItem.header"
      ) as TranslateResult,
      description: this.$t(
        "piceListContent.processingAndJustice.details.thirdItem.info"
      ) as TranslateResult,
      isIndividual: false
    },
    {
      price: "uzgadniane indywidualnie",
      time: 20,
      title: this.$t(
        "piceListContent.processingAndJustice.details.fourthItem.header"
      ) as TranslateResult,
      description: this.$t(
        "piceListContent.processingAndJustice.details.fourthItem.info"
      ) as TranslateResult,
      isIndividual: true
    }
  ];
  companyAndPrivateProducts = [
    {
      price: 370,
      time: 20,
      title: this.$t(
        "piceListContent.companiesAndPrivate.details.firstItem.header"
      ) as TranslateResult,
      description: this.$t(
        "piceListContent.companiesAndPrivate.details.firstItem.info"
      ) as TranslateResult,
      isIndividual: false,
      isFrom: false
    },
    {
      price: 640,
      time: 20,
      title: this.$t(
        "piceListContent.companiesAndPrivate.details.secondItem.header"
      ) as TranslateResult,
      description: this.$t(
        "piceListContent.companiesAndPrivate.details.secondItem.info"
      ) as TranslateResult,
      isIndividual: false,
      isFrom: false
    },
    {
      price: 300,
      time: 10,
      title: this.$t(
        "piceListContent.companiesAndPrivate.details.thirdItem.header"
      ) as TranslateResult,
      description: this.$t(
        "piceListContent.companiesAndPrivate.details.thirdItem.info"
      ) as TranslateResult,
      isIndividual: false,
      isFrom: true
    },
    {
      price: 320,
      time: 10,
      title: this.$t(
        "piceListContent.companiesAndPrivate.details.fourthItem.header"
      ) as TranslateResult,
      description: this.$t(
        "piceListContent.companiesAndPrivate.details.fourthItem.info"
      ) as TranslateResult,
      isIndividual: false,
      isFrom: false
    },
    {
      price: 435,
      time: 10,
      title: this.$t(
        "piceListContent.companiesAndPrivate.details.fifthItem.header"
      ) as TranslateResult,
      description: this.$t(
        "piceListContent.companiesAndPrivate.details.fifthItem.info"
      ) as TranslateResult,
      isIndividual: false,
      isFrom: false
    },
    {
      price: 570,
      time: 15,
      title: this.$t(
        "piceListContent.companiesAndPrivate.details.sixthItem.header"
      ) as TranslateResult,
      description: this.$t(
        "piceListContent.companiesAndPrivate.details.sixthItem.info"
      ) as TranslateResult,
      isIndividual: false,
      isFrom: false
    },
    {
      price: 1000,
      time: 20,
      title: this.$t(
        "piceListContent.companiesAndPrivate.details.seventhItem.header"
      ) as TranslateResult,
      description: this.$t(
        "piceListContent.companiesAndPrivate.details.seventhItem.info"
      ) as TranslateResult,
      isIndividual: false,
      isFrom: false
    },
    {
      price: 800,
      time: 20,
      title: this.$t(
        "piceListContent.companiesAndPrivate.details.eighthItem.header"
      ) as TranslateResult,
      description: this.$t(
        "piceListContent.companiesAndPrivate.details.eighthItem.info"
      ) as TranslateResult,
      isIndividual: false,
      isFrom: false
    },
    {
      price: 140,
      time: 15,
      title: this.$t(
        "piceListContent.companiesAndPrivate.details.ninthItem.header"
      ) as TranslateResult,
      description: this.$t(
        "piceListContent.companiesAndPrivate.details.ninthItem.info"
      ) as TranslateResult,
      isIndividual: false,
      isFrom: false
    },
    {
      price: 0,
      time: "-",
      title: this.$t(
        "piceListContent.companiesAndPrivate.details.tenthItem.header"
      ) as TranslateResult,
      description: this.$t(
        "piceListContent.companiesAndPrivate.details.tenthItem.info"
      ) as TranslateResult,
      isIndividual: true,
      isFrom: false
    },
    {
      price: 0,
      time: 20,
      title: this.$t(
        "piceListContent.companiesAndPrivate.details.eleventhItem.header"
      ) as TranslateResult,
      description: this.$t(
        "piceListContent.companiesAndPrivate.details.eleventhItem.info"
      ) as TranslateResult,
      isIndividual: true,
      isFrom: false
    }
  ];

  @Watch("$store.state.language")
  onPropertyChanged(value: string, oldValue: string) {
    this.policeProducts = [
      {
        price: 620,
        time: 20,
        title: this.$t(
          "piceListContent.processingAndJustice.details.firstItem.header"
        ) as TranslateResult,
        description: this.$t(
          "piceListContent.processingAndJustice.details.firstItem.info"
        ) as TranslateResult,
        isIndividual: false
      },
      {
        price: 250,
        time: 15,
        title: this.$t(
          "piceListContent.processingAndJustice.details.secondItem.header"
        ) as TranslateResult,
        description: this.$t(
          "piceListContent.processingAndJustice.details.secondItem.info"
        ) as TranslateResult,
        isIndividual: false
      },
      {
        price: 800,
        time: 20,
        title: this.$t(
          "piceListContent.processingAndJustice.details.thirdItem.header"
        ) as TranslateResult,
        description: this.$t(
          "piceListContent.processingAndJustice.details.thirdItem.info"
        ) as TranslateResult,
        isIndividual: false
      },
      {
        price: 0,
        time: 20,
        title: this.$t(
          "piceListContent.processingAndJustice.details.fourthItem.header"
        ) as TranslateResult,
        description: this.$t(
          "piceListContent.processingAndJustice.details.fourthItem.info"
        ) as TranslateResult,
        isIndividual: true
      }
    ];
    this.companyAndPrivateProducts = [
      {
        price: 370,
        time: 20,
        title: this.$t(
          "piceListContent.companiesAndPrivate.details.firstItem.header"
        ) as TranslateResult,
        description: this.$t(
          "piceListContent.companiesAndPrivate.details.firstItem.info"
        ) as TranslateResult,
        isIndividual: false,
        isFrom: false
      },
      {
        price: 640,
        time: 20,
        title: this.$t(
          "piceListContent.companiesAndPrivate.details.secondItem.header"
        ) as TranslateResult,
        description: this.$t(
          "piceListContent.companiesAndPrivate.details.secondItem.info"
        ) as TranslateResult,
        isIndividual: false,
        isFrom: false
      },
      {
        price: 400,
        time: 15,
        title: this.$t(
          "piceListContent.companiesAndPrivate.details.thirdItem.header"
        ) as TranslateResult,
        description: this.$t(
          "piceListContent.companiesAndPrivate.details.thirdItem.info"
        ) as TranslateResult,
        isIndividual: false,
        isFrom: true
      },
      {
        price: 300,
        time: 20,
        title: this.$t(
          "piceListContent.companiesAndPrivate.details.fourthItem.header"
        ) as TranslateResult,
        description: this.$t(
          "piceListContent.companiesAndPrivate.details.fourthItem.info"
        ) as TranslateResult,
        isIndividual: false,
        isFrom: false
      },
      {
        price: 410,
        time: 20,
        title: this.$t(
          "piceListContent.companiesAndPrivate.details.fifthItem.header"
        ) as TranslateResult,
        description: this.$t(
          "piceListContent.companiesAndPrivate.details.fifthItem.info"
        ) as TranslateResult,
        isIndividual: false,
        isFrom: false
      },
      {
        price: 570,
        time: 20,
        title: this.$t(
          "piceListContent.companiesAndPrivate.details.sixthItem.header"
        ) as TranslateResult,
        description: this.$t(
          "piceListContent.companiesAndPrivate.details.sixthItem.info"
        ) as TranslateResult,
        isIndividual: false,
        isFrom: false
      },
      {
        price: 1000,
        time: 20,
        title: this.$t(
          "piceListContent.companiesAndPrivate.details.seventhItem.header"
        ) as TranslateResult,
        description: this.$t(
          "piceListContent.companiesAndPrivate.details.seventhItem.info"
        ) as TranslateResult,
        isIndividual: false,
        isFrom: false
      },
      {
        price: 800,
        time: 20,
        title: this.$t(
          "piceListContent.companiesAndPrivate.details.eighthItem.header"
        ) as TranslateResult,
        description: this.$t(
          "piceListContent.companiesAndPrivate.details.eighthItem.info"
        ) as TranslateResult,
        isIndividual: false,
        isFrom: false
      },
      {
        price: 140,
        time: 20,
        title: this.$t(
          "piceListContent.companiesAndPrivate.details.ninthItem.header"
        ) as TranslateResult,
        description: this.$t(
          "piceListContent.companiesAndPrivate.details.ninthItem.info"
        ) as TranslateResult,
        isIndividual: false,
        isFrom: false
      },
      {
        price: 0,
        time: "-",
        title: this.$t(
          "piceListContent.companiesAndPrivate.details.tenthItem.header"
        ) as TranslateResult,
        description: this.$t(
          "piceListContent.companiesAndPrivate.details.tenthItem.info"
        ) as TranslateResult,
        isIndividual: true,
        isFrom: false
      },
      {
        price: 0,
        time: 20,
        title: this.$t(
          "piceListContent.companiesAndPrivate.details.eleventhItem.header"
        ) as TranslateResult,
        description: this.$t(
          "piceListContent.companiesAndPrivate.details.eleventhItem.info"
        ) as TranslateResult,
        isIndividual: true,
        isFrom: false
      }
    ];
  }

  get isPolice() {
    return this.$route.params.priceListName === EnumPriceLIstPaths.POLICE;
  }

  get isCompanyAndPrivate() {
    return (
      this.$route.params.priceListName ===
      EnumPriceLIstPaths.COMPANY_AND_PRIVATE
    );
  }
}
