




























import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class ProductPrice extends Vue {
  @Prop()
  price;

  @Prop()
  time;

  @Prop()
  title;

  @Prop()
  description;

  @Prop()
  isPrivate;

  @Prop({ default: false })
  isIndividual;

  @Prop({ default: false })
  isFrom;

  get vatPrice() {
    return (Math.round(this.price * 1.23 * 100) / 100).toFixed(2);
  }
}
